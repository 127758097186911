import React, { useState, useEffect } from 'react';
import { BigInteger } from 'biginteger';
import SvgInline from './SvgInline';

import './App.css';

function App() {

  const [ssid, setSsid] = useState('');
  const [pwd, setPwd] = useState('');
  const [code, setCode] = useState('87655678');
  const [qr, setQr] = useState();
  const [connected, setConnected] = useState(false);
  const [char, setChar] = useState();

  const serviceUuid = 'b63f17a3-13fb-4b86-b869-460a7e0f2b86';
  const characteristicUuid = '11a4c112-fc08-4ef1-88fc-d1d627c2cd90';


  const initiateBluetooth = (reset) => {
    console.log('Requesting devices');
    navigator.bluetooth.requestDevice({ filters: [{ services: [serviceUuid] }] })
      .then(device => {
        console.log('Connecting to GATT Server...');
        return device.gatt.connect();
      })
      .then(server => {
        console.log('Getting Service...');
        setConnected(true);
        return server.getPrimaryService(serviceUuid);
      })
      .then(service => {
        console.log('Getting Characteristics...');
        if (characteristicUuid) {
          // Get all characteristics that match this UUID.
          return service.getCharacteristics(characteristicUuid);
        }
        // Get all characteristics.
        return service.getCharacteristics();
      })
      .then(characteristics => {
        setChar(characteristics[0]);
        characteristics[0].readValue()
          .then(value => {
            const decoder = new TextDecoder('utf-8');
            const strValue = decoder.decode(value);
            console.log('Characteristic value: ' + strValue);
            const wifiCreds = JSON.parse(strValue);
            if (wifiCreds && wifiCreds.length) {
              setSsid(wifiCreds[0].ssid);
              setPwd(wifiCreds[0].pwd);
              setQr(wifiCreds[0].code);

              const payloadBase36 = wifiCreds[0].code.indexOf('://') > 0 ? wifiCreds[0].code.split('://')[1].substring(0, 9) : wifiCreds[0].code.substring(0, 9);
              const payloadBinary = BigInteger.parse(payloadBase36, 36).toString(2);
              const pairingCode = BigInteger.parse(payloadBinary.substring(payloadBinary.length-26), 2).toString(10);
              
              setCode(pairingCode);
            }
          })
      })
      .catch(error => {
        setConnected(false);
        console.log('Argh! ' + error);
      });
  }

  const disallowedCodes = [
    "00000000",
    "11111111",
    "22222222",
    "33333333",
    "44444444",
    "55555555",
    "66666666",
    "77777777",
    "88888888",
    "99999999",
    "12345678",
    "87654321"
  ];

  const saveWifi = () => {
    if (disallowedCodes.includes(code)) {
      alert("Please pick a less simple code");
      return;
    }

    const encoder = new TextEncoder();

    char.writeValue(encoder.encode(JSON.stringify({ ssid: ssid, pwd: pwd, code: code })));
    setConnected(false);
  }

  const factoryReset = () => {
    const encoder = new TextEncoder();

    char.writeValue(encoder.encode(JSON.stringify({ reset: true })));
    setConnected(false);
  }

  return (
    <div className="App">
      {connected &&
        <header className="App-header">
          <div>
            <label htmlFor='ssid'>WiFi Network SSID (Name)</label>
            <input type="text" name="ssid" value={ssid} onChange={e => setSsid(e.target.value)} />
          </div>
          <div>
            <label htmlFor='pwd'>Password</label>
            <input type="text" name="pwd" value={pwd} onChange={e => setPwd(e.target.value)} />
          </div>
          <div>
            <label htmlFor='pwd'>Set an 8-digit pairing code</label>
            <input type="text" name="code" value={code} onChange={e => setCode(e.target.value)} />
          </div>
          <div>
            { qr && 
            <SvgInline url={`https://ho.gy/hkqr/${qr.split('://')[1]}`} />
}
          </div>
          <button onClick={saveWifi}>Save</button><br/><br/>
          <button onClick={factoryReset}>Factory Reset</button> 
        </header>
      }
      {!connected && <div>
        <button onClick={initiateBluetooth}>Connect</button>
      </div>
      }

    </div>
  );
}

export default App;
